export enum ROUTES_PATH {
  DASHBOARD = '',
  MAPS = 'maps',
  CHANGES = 'changes',
  SF_ORGS = 'referencemodels-external',
  REFERENCE_MODEL = 'referencemodels',
  METADATA_DICTIONARIES = 'metadata-dictionaries',
  URL_LIBRARY = 'url-library',
  RELEASES = 'releases',
  SPACES = 'teams',
  STORIES = 'stories',
  REQUIREMENTS = 'requirements',
  FEEDBACKS = 'feedback',
  PROFILE = 'profile',
  SIGN_IN = 'signin',
  SIGN_UP = 'signup',
  SIGN_UP_SUCCESS = 'signup-success',
  FORGOT_PASS = 'forgot-pass',
  NEW_PASS = 'new-pass',
  AUTH = 'auth',
  ACCOUNT_ACCEPT = 'account-accept',
  ACCOUNT = 'account',
  TASKS = 'tasks',
  SF_REFRESH_CALLBACK = 'auth/refresh/callback',
  SF_CONNECT_CALLBACK = 'auth/connect/callback',
  NOT_FOUND = '404'
}
